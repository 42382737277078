.centered {
    text-align: center;
}

.indented-1 {
    margin-left: 20px; /* or use padding-left: 20px; */
}

.indented-2 {
    margin-left: 40px; /* or use padding-left: 20px; */
}

.back-button {
    padding: 5px;
    color: gray;
    text-decoration: none;
}