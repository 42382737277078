:root {
  --background-color: #F2F2F7;
  --accent-color: #083D77;
  --text-color: #000000;
  --secondary-text-color: #3c3c43b8;
  --tertiary-text-color: c#7a7a85dc;
  --button-color: #ffffff28;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

body>#root {
  height: 99%
}

html {
  font-size: 17px;
  width: 100%;
  height: 100%;
}

body {
  margin: auto;
  padding: 0rem 0rem;
  max-width: 1000px;
  height: 100%;

  background-color: var(--background-color);

  animation: fade-in 2s ease;
}

a {
  color: gray;
  text-decoration: none;

  transition: font-size 0.3s ease-in-out 0ms;
}

a:hover {
  color: var(--accent-color);
}

@media only screen and (max-width: 1200px) {
  body {
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 850px) {
  html {
    font-size: 16px;
  }

  body {
    padding: 1rem 2rem;
    align-items: center;
  }
}
