:root {
    --text-color: #000000;
    --secondary-text-color: #3c3c43b8;
    --tertiary-text-color: #7a7a85dc;
}  

.text {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
}

.largeTitle {
    font-size: 34px;
    line-height: 41px;
}

.title {
    font-size: 28px;
    line-height: 34px;
}

.title2 {
    font-size: 22px;
    line-height: 28px;
}

.title3 {
    font-size: 20px;
    line-height: 25px;
}

.headline {
    font-size: 17px;
    line-height: 22px;
}

.body {
    font-size: 17px;
}

@media only screen and (min-width: 1000px) {
    /* Uses AX3 Apple Typography */
    .largeTitle {
        font-size: 84px;
        line-height: 95px;
    }

    .title {
        font-size: 48px;
        line-height: 57px;
    }

    .title2 {
        font-size: 44px;
        line-height: 52px;
    }    

    .headline {
        font-size: 40px;
    }
}
  