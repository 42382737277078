.mainPage {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .mainPage {
        flex-direction: column;
    }

    header {
        max-width: 100%;
        padding: 2rem 0rem 4rem 0rem;
    }
}
