button {
    display: 'flex';
    align-items: center;
    justify-content: center;
    
    border: 0;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out 0ms;
}

button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out 0ms  
  }
